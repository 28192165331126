import React from "react";
import happy from "../assets/icons/happy-emoji.png";
import instagram from "../assets/icons/instagram.png";
import whatsapp from "../assets/icons/whastapp.png";

const ComingSoon = () => {
  return (
    <div className="container coming-soon">
      <img className="sad" src={happy} alt="Happy emoji" width="80" />
      <p className="coming-soon-message">
        Thank you for visiting Mr. Gardener! Please let us know what would you
        like to order on Instagram or whatsapp with{" "}
        <span className="discount">FLAT 20% DISCOUT</span> with assured GIFTS.
        Unfortunately due to some technical issues we can&#8217;t take orders
        from our website for some time inconvenience is deeply regreted please
        order your desired plant or service on our Instagram or whatsapp.
      </p>
      <h3 className="for-now">For now you can</h3>
      <div className="socials">
        <a
          href="https://wa.me/+919149182937?text=I%20would%20Like%20to%20buy%20one%20of%20your%20plants."
          target="_blank"
          rel="noreferrer"
          className="links whatsapp">
          <h5>Buy on</h5>
          <img src={whatsapp} alt="whatsapp" width={50} />
        </a>
        <a
          href="https://instagram.com/gardener_foryou?igshid=YmMyMTA2M2Y="
          target="_blank"
          rel="noreferrer"
          className="links instagram">
            <h5>Check us on</h5>
          <img src={instagram} alt="chat on whatsapp" width={50} />
        </a>
      </div>
    </div>
  );
};

export default ComingSoon;
