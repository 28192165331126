import React from "react";
import specificationImg from "../assets/icons/document.png";
import secureShippingImg from "../assets/icons/box.png";
import guidanceImg from "../assets/icons/guidance.png";

const HowWeWork = () => {
  return (
    <div className="works container">
      <h1>How We Work</h1>
      <p className="works-sub-heading">
        Add a touch of green to your home/office in three simple steps and
        become a plant parent.
      </p>
      <div className="works-description">
        <div className="works-description-card">
          <img src={specificationImg} alt="simplified" />
          <h3 className="description-heading">Plants simplified</h3>
          <p className="description">
            Order plants ready to be placed in your home, office or garden. Just
            Unpack, Relax and Enjoy your green buddies.
          </p>
        </div>
        <div className="works-description-card">
          <img src={secureShippingImg} alt="shipping" />
          <h3 className="description-heading">Secure Shipping</h3>
          <p className="description">
            Our unique packaging will hold the plants in place and let the plant
            breathe so that it reaches you fresh without any mess.
          </p>
        </div>
        <div className="works-description-card">
          <img src={guidanceImg} alt="guidance" />
          <h3 className="description-heading">Detailed Guidance</h3>
          <p className="description">
            Get detailed plant care instructions from the website as well as
            real-time guidance from our Garden Experts on Whatsapp.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
