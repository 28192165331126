import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/Logo.svg";
import cart from "../assets/icons/cart.svg";

const NavBar = () => {
  return (
    <nav className="nav container">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <ul className="nav-links">
        <li className="nav-link-item active">
          <NavLink to="/products">Products</NavLink>
        </li>
        <li className="nav-link-item">
          <NavLink to="/aboutus">About Us</NavLink>
        </li>
        <li className="nav-link-item hidden">
          <img src={cart} alt="cart" width={20} height={20} />
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
