import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="hero container">
      <div className="hero-description">
        <h1 className="hero-description_heading">
          The Best Plant Under One Roof
        </h1>
        <Link to="/products" className="hero-description_cta">
          Buy Now
        </Link>
      </div>
    </div>
  );
};

export default Hero;
