import React from "react";
import aboutus from "../assets/aboutus.jpg";

const AboutUs = () => {
  return (
    <div className="aboutus">
      <img src={aboutus} alt="about us" className="aboutus-img" />
      <h2 className="aboutus-heading">About Us</h2>
      <p className="container aboutus-desc">
        <span className="logo-title">Mr. Gardener</span> germinated in 2022 from
        a promise to make ‘green and healthy’ a click away for all Indians
        Having plants in our homes or in our offices doesn’t just look good, it
        also boosts our mood, makes us more productive, and cleans the air
        around us by absorbing toxins Most of us being urban dwellers spending
        their days in apartments with limited access to parks and ecological
        reserves, have no way of feeling close to nature and experiencing the
        benefits of being around plants. Ordering a pizza is easy but ever heard
        of ordering a plant to your doorstep? This is where Mr. Gardener comes
        in.
      </p>
    </div>
  );
};

export default AboutUs;
