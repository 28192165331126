import React from "react";
import Hero from "../components/Hero";
import Products from "../components/ProductSection";
import HowWeWork from "../components/HowWeWork";
import Footer from "../components/Footer";
import Founder from "../components/Founder";

const Home = () => {
  return (
    <>
      <header>
        <Hero />
      </header>
      <main>
        <Products />
        <HowWeWork />
      </main>
      <Footer />
      <Founder />
    </>
  );
};

export default Home;
