import React from "react";
import logo from "../assets/Logo.svg";
import mail from "../assets/icons/mail.png";
import whatsapp from "../assets/icons/whatsapp-white.png";
import twitter from "../assets/icons/twitter.png";
import instagram from "../assets/icons/instagram-white.png";

const Footer = () => {
  return (
    <footer className="container">
      <div className="logo-container">
        <img src={logo} alt="logo" />
        <div className="footer-social">
          <a href="mailto:ordersupport@mrgardener.co.in">
            <img src={mail} alt="mail" />
          </a>
          <a
            href="https://wa.me/+919149182937?text=I%20would%20Like%20to%20buy%20one%20of%20your%20plants."
            target="_blank"
            rel="noreferrer">
            <img src={whatsapp} alt="whatsapp" />
          </a>
          <a
            href="https://twitter.com/gardenerforyou"
            target="_blank"
            rel="noreferrer">
            <img src={twitter} alt="twitter" />
          </a>
          <a
            href="https://instagram.com/gardener_foryou?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noreferrer">
            <img src={instagram} alt="instagram" />
          </a>
        </div>
      </div>
      <form
        className="form"
        action="https://formsubmit.co/ordersupport@mrgardener.co.in"
        method="POST">
        <div className="form-top">
          <div className="form-group name">
            <input
              type="text"
              className="form-control input-name"
              placeholder="Name"
              name="name"
              required
            />
          </div>
          <input type="hidden" name="_subject" value="New message from web" />
          <div className="form-group email">
            <input
              type="email"
              className="form-control input-email"
              placeholder="Email"
              name="email"
              required
            />
          </div>
        </div>

        <div className="form-group message">
          <textarea
            type="text"
            className="form-control input-message"
            placeholder="Message..."
            name="message"
            required
          />
        </div>
        <button className="btn btn-submit">send</button>
      </form>
    </footer>
  );
};

export default Footer;
