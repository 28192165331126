import React from "react";

const Founder = () => {
  return (
    <div className="container founder">
      Founder-Shantanu Sharma | We are here to inspire a world where every plant
      journey starts from Mr. Gardener Go Greener Live Healthier | Since-August
      2022
    </div>
  );
};

export default Founder;
