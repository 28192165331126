import React from "react";
import { Link } from "react-router-dom";
import { collections } from "../data/PlantCollection";
// import ProductCard from "./ProductCard";
// import gardeningImg from "../assets/icons/gardening.png";
// import petImg from "../assets/icons/pet.png";
// import sunImg from "../assets/icons/sun.png";
// import wateringImg from "../assets/icons/watering-plants.png";

const ProductSection = () => {
  return (
    <section className="products container">
      <h1 className="products-heading">Our Products</h1>
      <div className="products-catalog">
        {collections.map(({ url, type }, index) => (
          <Link to="/products" key={index}>
            <img
              className={`products-catalog-img`}
              src={url}
              alt={type}
              width={350}
            />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ProductSection;
