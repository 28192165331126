import indoor from "../assets/collections/indoor.png";
import flowering from "../assets/collections/flowering.png";
import hanging from "../assets/collections/hanging.png";
import cacti from "../assets/collections/cacticacti.png";
import lowLight from "../assets/collections/low-light.png";
import airPurify from "../assets/collections/air-purifying.png";
import medicinal from "../assets/collections/medicinal.png";
import special from "../assets/collections/special.png";

export const collections = [
  { url: indoor, type: "indoor and Outdoor" },
  { url: flowering, type: "flowering" },
  { url: hanging, type: "hanging" },
  { url: cacti, type: "cacti and succulents" },
  { url: lowLight, type: "low light" },
  { url: airPurify, type: "air purifying" },
  { url: medicinal, type: "medicinal" },
  { url: special, type: "our sepcial products" },
];
